<h3 *ngIf="panel.overrated">You actually like this stuff?</h3>
<h3 *ngIf="!panel.overrated">You just didn't get it</h3>
<div class="container">
    <div class="column" *ngFor="let column of animeByColumns">
        <table>
            <thead>
                <tr>
                    <th colspan="2" class="animeTitle">Anime</th>
                    <th>Global Score</th>
                    <th>Your Score</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let valuedAnime of column">
                    <td class="poster" [ngStyle]="{
                        'background-image': 'url(' + valuedAnime.anime.anime.thumbnailUrl + ')'
                    }"></td>
                    <td class="animeTitle">
                        <div class="titleContainer">
                            <div class="default">
                                {{ valuedAnime.anime.anime.defaultTitle }}
                            </div>
                            <div class="english">
                                {{ valuedAnime.anime.anime.englishTitle }}
                            </div>
                        </div>
                    </td>
                    <td>{{valuedAnime.anime.globalScore}}</td>
                    <td>{{valuedAnime.anime.userScore}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>