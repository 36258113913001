<div class="container" [ngClass]="'columns-' + size.columns">
    <div class="icon" [ngStyle]="{
        'background-image': 'url(' + properties.icon + ')'
    }"></div>
    <h3 class="name">{{ panel.award.name }}</h3>
    <div class="description">{{ panel.award.description }}</div>
    <div class="reason">{{ panel.award.reason }}</div>
    <div class="contributingAnime">
        <div *ngFor="let anime of contributingAnime" class="thumbnail" [ngStyle]="{
            'background-image': 'url(' + anime.thumbnailUrl + ')'
        }"></div>
    </div>
</div>