<div class="content">
    <h1><a routerLink="">WrongOpinions.moe</a></h1>
    <h2>{{username}}</h2>
    <app-progress-bar *ngIf="loading || (pendingJob && !pendingJob.failed)" [intervals]="loadingIntervals" [progress]="loadingProgress"></app-progress-bar>
    <div class="status">
        <span class="statusDescription">{{statusDescription}}</span>
        <span class="eta" *ngIf="pendingJob && pendingJob.completed && pendingJob.now">~{{loadingEstimatedCompletionTime}}</span>
        <button [disabled]="loading" (click)="startJob()">{{ pendingJob ? 'Resubmit' : 'Update'}}</button>
    </div>
    <app-dom-image>
        <div class="container" *ngIf="results">
            <span class="fineprint">WrongOpinions.moe</span>
            <div class="titleRow">
                <div class="icon" [ngStyle]="{
                    'background-image': 'url(' + getBakaIcon() + ')'
                }"></div>
                <div class="usernameContainer">
                    <div class="username">{{username}}</div>
                    <div class="bakaRank">
                        <span class="bakaRankName">{{results.bakaRank.name}}</span>
                        <div class="bakaRankCaptionContainer">
                            <div class="bakaRankCaption">
                                <div class="measurement">{{results.bakaRank.description}}</div>
                                <div class="visible">{{results.bakaRank.description}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bakaScoreContainer">
                    <div class="bakaScoreTitle">
                        Baka Score:
                    </div>
                    <div class="bakaScore">
                        {{ results.bakaScore | number : '1.0-0' }}
                    </div>
                </div>
            </div>
            <div class="awardsArea">
                <app-panel-layout [columns]=2 [rows]=7 [panels]="panels"></app-panel-layout>
            </div>
        </div>
    </app-dom-image>
    <img [hidden]="!dataUrl" [src]="dataUrl" class="renderedImage" />
    <div class="sharing" *ngIf="dataUrl">
        <a class="button" download [href]="dataUrl">Save</a>
        <a class="button" *ngIf="canShare" (click)="share()">Share</a>
    </div>
</div>