<app-panel-outlines [columns]="columns" [rows]="rows" [panelLayout]="panelLayout" [gaps]="10" [margins]="5" [columnWidth]="500" [rowHeight]="130"></app-panel-outlines>
<div
    class="panel-grid"
    [ngStyle]="{
        '--columns': columns,
        '--rows': rows
    }"
>
    <div
        *ngFor="let panel of panelLayout"
        class="panel"
        [ngStyle]="{
            'grid-column-start': panel.position.startColumn + 1,
            'grid-column-end': panel.position.endColumn + 1,
            'grid-row-start': panel.position.startRow + 1,
            'grid-row-end': panel.position.endRow + 1
        }"
    >
        <div [ngSwitch]="getPanelType(panel)">
            <app-score-difference-panel *ngSwitchCase="'score-difference'" [panel]="$any(panel.panel)" [size]="panel.size"></app-score-difference-panel>
            <app-unpopular-score-panel *ngSwitchCase="'unpopular-score'" [panel]="$any(panel.panel)" [size]="panel.size"></app-unpopular-score-panel>
            <app-special-award-panel *ngSwitchCase="'special-award'" [panel]="$any(panel.panel)" [size]="panel.size"></app-special-award-panel>
            <app-series-direction-correlation-panel *ngSwitchCase="'series-direction'" [panel]="$any(panel.panel)"></app-series-direction-correlation-panel>
        </div>
    </div>
</div>